import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import { typo } from '../../../styles';
import { device } from '../../../styles/constants';

const SpaceBetweenLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const VerticalCenterLayout = styled.div`
  display: flex;
  align-items: center;
`;

const Check = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background: ${({ theme }) => theme.radio.check.background};
  border: 2px solid ${({ theme }) => theme.radio.border};
  border-radius: 50%;

  @media ${device.mobile} {
    width: 18px;
    height: 18px;
  }
`;

const MainText = styled.div`
  ${typo.L_B};

  @media ${device.mobile} {
    ${typo.S_B};
  }
`;

const SubLargeText = styled.div`
  margin-top: 4px;
  ${typo.XXL_B};
`;

const SubText = styled.div`
  margin-top: 8px;
  white-space: pre-wrap;
  ${typo.S};

  @media ${device.mobile} {
    margin-top: 4px;
    ${typo.XS};
  }
`;

const CenterContent = styled.div`
  flex: 1 0 auto;
  margin-top: 24px;
  line-height: 1;
`;

const CenterMainText = styled.div`
  margin-top: 20px;
  ${typo.XXL_B};
  text-align: center;
`;

const CenterSubText = styled.div`
  margin-top: 4px;
  ${typo.XS};
  text-align: center;
  white-space: pre-wrap;
  line-height: 1.5;
`;

const CenterIcon = styled(Icon)`
  &&& {
    width: 100%;
    font-size: 64px;
  }
`;

const RightText = styled.div`
  flex: 0 0 auto;
  margin-left: 8px;
  ${typo.M};

  @media ${device.mobile} {
    ${typo.S};
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  min-width: 210px;
  padding: 24px;
  background: ${({ theme }) => theme.radio.background.standard};
  border: 2px solid ${({ theme }) => theme.radio.border};
  border-radius: 10px;
  box-sizing: border-box;
  line-height: 1.5;
  cursor: pointer;

  ${Check} {
    width: 30px;
    height: 30px;
    margin-right: 16px;
    box-sizing: border-box;

    @media ${device.mobile} {
      width: 24px;
      height: 24px;
    }
  }

  @media ${device.mobile} {
    padding: 16px;

    ${Check} {
      width: 24px;
      height: 24px;
    }
  }
`;

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  ${typo.M};
  cursor: pointer;
`;

const Label = styled.label`
  display: inline-block;
  ${typo.S};
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  input:focus + ${Container} {
    ${Check} {
      box-shadow: 0 0 0 3px ${({ theme }) => theme.input.focus};
    }
  }
  input:focus + ${CardContainer} {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.input.focus};
  }
  input:checked + * {
    ${Check} {
      border-color: ${({ theme }) => theme.radio.check.selected};
      :after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 14px;
        height: 14px;
        margin: auto;
        background: ${({ theme }) => theme.radio.check.selected};
        border: 1px solid ${({ theme }) => theme.radio.check.selected};
        border-radius: 50%;
        @media ${device.mobile} {
          width: 11px;
          height: 11px;
        }
      }
    }
  }
  input:checked + ${CardContainer} {
    background: ${({ theme }) => theme.radio.background.selected};
    border-color: ${({ theme }) => theme.radio.background.selected};
    color: ${({ theme }) => theme.radio.text.selected};
    ${Check} {
      :after {
        width: 18px;
        height: 18px;
        @media ${device.mobile} {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  input:disabled + ${CardContainer} {
    opacity: 0.4;
    cursor: default;
  }
`;

const ColumnCardLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -8px;
  margin-right: -16px;
  :not(:first-child) {
    margin-top: 16px;
  }
  > ${Label} {
    flex: 1;
    margin-bottom: 8px;
    margin-right: 16px;
  }
`;

const RowCardLayout = styled.div`
  :not(:first-child) {
    margin-top: 16px;
  }
  > ${Label} {
    display: block;
    :not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

const RowLayout = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-top: 18px;
  padding-left: 24px;
  > ${Label} {
    :not(:first-child) {
      margin-top: 20px;
    }
  }
`;

// カードタイプ
const CardItem = ({
  // @ts-ignore
  mainText,
  // @ts-ignore
  subText,
  // @ts-ignore
  subLargeText,
  // @ts-ignore
  rightText,
  // @ts-ignore
  centerIcon,
  // @ts-ignore
  centerMainText,
  // @ts-ignore
  centerSubText,
  // @ts-ignore
  field,
  // @ts-ignore
  form, // eslint-disable-line no-unused-vars
  // @ts-ignore
  meta, // eslint-disable-line no-unused-vars
  ...props
}) => (
  <Label>
    <input {...field} {...props} type="radio" />
    <CardContainer>
      <SpaceBetweenLayout>
        <VerticalCenterLayout>
          <Check />
          <div>
            <MainText>{mainText}</MainText>
            {subLargeText && <SubLargeText>{subLargeText}</SubLargeText>}
            {subText && <SubText>{subText}</SubText>}
          </div>
        </VerticalCenterLayout>
        {rightText && <RightText>{rightText}</RightText>}
      </SpaceBetweenLayout>
      {(centerMainText || centerSubText) && (
        <CenterContent>
          {centerIcon && <CenterIcon name={centerIcon} />}
          {centerMainText && <CenterMainText>{centerMainText}</CenterMainText>}
          {centerSubText && <CenterSubText>{centerSubText}</CenterSubText>}
        </CenterContent>
      )}
    </CardContainer>
  </Label>
);

CardItem.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  meta: PropTypes.object,
  mainText: PropTypes.string.isRequired,
  subLargeText: PropTypes.string,
  subText: PropTypes.string,
  rightText: PropTypes.string,
  centerIcon: PropTypes.string,
  centerMainText: PropTypes.string,
  centerSubText: PropTypes.string,
};

CardItem.defaultProps = {
  field: {},
  form: null,
  meta: null,
  subLargeText: '',
  subText: '',
  rightText: '',
  centerIcon: '',
  centerMainText: '',
  centerSubText: '',
};

// 通常タイプ
const Item = (
  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  { label, field, form, meta, ...props } // eslint-disable-line no-unused-vars
) => (
  <Label>
    <input {...field} {...props} type="radio" />
    <Container>
      {/* @ts-ignore */}
      <Check isSmall />
      <div>{label}</div>
    </Container>
  </Label>
);

Item.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string.isRequired,
};

Item.defaultProps = {
  field: {},
  form: null,
  meta: null,
};

const RadioButton = {
  CardItem,
  Item,
  ColumnCardLayout,
  RowCardLayout,
  RowLayout,
};

export default RadioButton;
