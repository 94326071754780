import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mixinStyle } from '../../../styles';
import { device } from '../../../styles/constants';

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  max-width: 100%;
  vertical-align: top;
  overflow: hidden;
  box-sizing: border-box;
  ${mixinStyle.INPUT_BOX};
  ${mixinStyle.INPUT_FOCUS};
`;

const Button = styled.div`
  position: absolute;
  width: 52px;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: ${({ theme }) => theme.input.button.background};
  pointer-events: none;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    width: 6px;
    height: 6px;
    margin: auto;
    border-bottom: 1px solid ${({ theme }) => theme.input.button.text};
    border-right: 1px solid ${({ theme }) => theme.input.button.text};
    transform: translate(0, -30%) rotate(45deg);
  }

  @media ${device.mobile} {
    width: 44px;
  }
`;

const Select = styled.select`
  appearance: none;
  height: 100%;
  &::-ms-expand {
    display: none;
  }
  ${mixinStyle.INPUT_CONTENT};
  padding-right: 60px;
  border: none;
  cursor: pointer;
  :focus {
    outline: none;
  }

  @media ${device.mobile} {
    padding-right: 52px;
  }
`;

const SelectInput = (
  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  { hasError, field, form, meta, className, ...props } // eslint-disable-line no-unused-vars
) => (
  // @ts-ignore
  <Wrapper hasError={hasError} className={className}>
    <Select {...field} {...props} />
    <Button />
  </Wrapper>
);

SelectInput.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  meta: PropTypes.object,
  hasError: PropTypes.bool,
  className: PropTypes.string,
};

SelectInput.defaultProps = {
  field: {},
  form: null,
  meta: null,
  hasError: false,
  className: null,
};

export default SelectInput;
