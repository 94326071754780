import { Form } from 'formik';

import Label from './Label';
import TextInput from './TextInput';
import TextArea from './TextArea';
import RadioButton from './RadioButton';
import BirthDateSelector from './BirthDateSelector';
import ErrorMessage from './ErrorMessage';
import ImageInput from './ImageInput';
import ImageInputGroup from './ImageInputGroup';
import SelectInput from './SelectInput';
import CheckBox from './CheckBox';
import TopError from './TopError';
import ErrorScroller from './ErrorScroller';

// TODO: this may create unexpected bugs if we accidentally overwrite
//       Form if it's not immutable
Object.assign(Form, {
  Label,
  TextInput,
  TextArea,
  RadioButton,
  BirthDateSelector,
  ErrorMessage,
  ImageInput,
  ImageInputGroup,
  SelectInput,
  CheckBox,
  TopError,
  ErrorScroller,
});

export default Form;
export {
  Label,
  TextInput,
  TextArea,
  RadioButton,
  BirthDateSelector,
  ErrorMessage,
  ImageInput,
  ImageInputGroup,
  SelectInput,
  CheckBox,
  TopError,
  ErrorScroller,
}
