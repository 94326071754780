import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Button from '../Button';
import Icon from '../Icon';
import { typo } from '../../../styles';

const MAX_CANVAS_WH = 1600;
const IMAGE_QUALITY = 0.9;

const ImageInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 192px;
  height: 192px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.imageInput.background};
  border-radius: 10px;
  :hover {
    // @ts-ignore
    ${({
      // @ts-ignore
      hasImage,
      theme,
    }) =>
      !hasImage &&
      css`
        background-color: ${theme.imageInput.backgroundHovered};
      `}
  }

  input {
    position: absolute;
    width: inherit;
    height: 100%;
    opacity: 0;
    border: none;
    cursor: pointer;
  }
  img {
    width: auto;
    height: auto;
    max-width: 192px;
    max-height: 192px;
  }
`;

const DeleteButton = styled.button`
  ${Button.WarningCss};
  &&& {
    position: absolute;
    bottom: 4px;
    right: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 34px;
    min-width: 80px;
    padding: 0 8px;
    border-radius: 5px;
    ${typo.S_B};
  }
`;

const DeleteIcon = styled(Icon)`
  margin-right: 4px;
`;

const UploadButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 94px;
  line-height: 1;
  color: ${({ theme }) => theme.imageInput.text};
`;

const UploadText = styled.div`
  font-size: 16px;
`;

// @ts-ignore
const resizeImage = image => {
  const canvas = document.createElement('canvas');

  let width = image.width;
  let height = image.height;

  if (width > height) {
    if (width > MAX_CANVAS_WH) {
      height = Math.round((height * MAX_CANVAS_WH) / width);
      width = MAX_CANVAS_WH;
    }
  } else {
    if (height > MAX_CANVAS_WH) {
      width = Math.round((width * MAX_CANVAS_WH) / height);
      height = MAX_CANVAS_WH;
    }
  }

  canvas.width = width;
  canvas.height = height;

  const context = canvas.getContext('2d');
  // @ts-ignore
  context.drawImage(image, 0, 0, width, height);
  return canvas.toDataURL('image/jpeg', IMAGE_QUALITY);
};

// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore
const ImageInput = ({ onSet, onDelete, src, name }) => {
  const [image, setImage] = useState(src);

  useEffect(() => {
    setImage(src);
  }, [src]);

  // @ts-ignore
  const addImage = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = readerEvent => {
      const newImage = new Image();
      // @ts-ignore
      // @ts-ignore
      newImage.src = readerEvent.target.result;
      newImage.onload = () => {
        const resizedImage = resizeImage(newImage);
        setImage(resizedImage);
        if (typeof onSet === 'function') {
          onSet(resizedImage);
        }
      };
    };
    reader.readAsDataURL(file);
  };

  const deleteImage = () => {
    setImage('');
    if (typeof onDelete === 'function') {
      onDelete();
    }
  };

  return (
    <ImageInputWrapper
      // @ts-ignore
      hasImage={!!image}
    >
      {image ? (
        <>
          <img alt={name} src={image} />
          <DeleteButton onClick={deleteImage}>
            <DeleteIcon name={Icon.ASSETS.COMMON.DELETE} />
            削除
          </DeleteButton>
        </>
      ) : (
        <>
          <UploadButton>
            <Icon name={Icon.ASSETS.COMMON.ADD} />
            <UploadText>画像を追加</UploadText>
          </UploadButton>
          <input
            type="file"
            id={name}
            accept="image/*"
            name={name}
            onChange={addImage}
          />
        </>
      )}
    </ImageInputWrapper>
  );
};

ImageInput.propTypes = {
  name: PropTypes.string.isRequired,
  src: PropTypes.node,
  onSet: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

export default ImageInput;
