import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { typo } from '../../../styles';
import { device } from '../../../styles/constants';

const Check = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border: 2px solid ${({ theme }) => theme.input.check.border};
  border-radius: 5px;
  box-sizing: border-box;

  @media ${device.mobile} {
    width: 18px;
    height: 18px;
  }
`;

const Label = styled.label`
  display: inline-flex;
  align-items: center;
  margin-top: 16px;
  margin-left: 16px;
  ${typo.M};
  cursor: pointer;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  input:focus + ${Check} {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.input.focus};
  }

  input:checked + ${Check} {
    position: relative;
    background: ${({ theme }) => theme.input.check.background};
    border: none;
    :before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      width: 5px;
      height: 12px;
      margin: auto;
      border-bottom: 2px solid ${({ theme }) => theme.input.check.text};
      border-right: 2px solid ${({ theme }) => theme.input.check.text};
      transform: translate(0, -1px) rotate(50deg);
    }
  }
`;

const CheckBox = (
  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  { field, form, meta, label, ...props } // eslint-disable-line no-unused-vars
) => (
  <Label>
    <input type="checkbox" {...field} {...props} />
    <Check />
    <div>{label}</div>
  </Label>
);

CheckBox.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string.isRequired,
};

CheckBox.defaultProps = {
  field: {},
  form: null,
  meta: null,
};

export default CheckBox;
