import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  getIncrementalOptions,
  checkIsValidDate,
} from '../../../utils/helpers';
import SelectInput from './SelectInput';

// @ts-ignore
const getDateValuesFromString = value => {
  const [yearString, monthString, dayString] = value
    ? value.split('T')[0].split('-')
    : [];
  const valueYear = Number(yearString) || undefined;
  const valueMonth = Number(monthString) || undefined;
  const valueDay = Number(dayString) || undefined;
  return { valueYear, valueMonth, valueDay };
};

const BirthDateSelector = ({
  // @ts-ignore
  field,
  // @ts-ignore
  form,
  // @ts-ignore
  // eslint-disable-next-line no-unused-vars
  meta,
  // @ts-ignore
  // eslint-disable-next-line no-unused-vars
  label,
  // @ts-ignore
  fromYear,
  // @ts-ignore
  minAge,
  ...props
}) => {
  const { name, value } = field;
  const { setFieldValue, setFieldTouched, touched } = form;

  let toYear = new Date().getFullYear();
  if (minAge) {
    toYear = toYear - minAge;
  }

  const { valueYear, valueMonth, valueDay } = getDateValuesFromString(value);

  const [validLastDay, setValidLastDay] = useState(
    // @ts-ignore
    valueYear && valueMonth ? new Date(valueYear, valueMonth, 0).getDate() : 31
  );

  const handleChange = ({
    year = valueYear,
    month = valueMonth,
    day = valueDay,
  }) => {
    if (year && month && day) {
      if (checkIsValidDate(year, month, day)) {
        const birthDate = new Date(0);
        // @ts-ignore
        // @ts-ignore
        birthDate.setUTCFullYear(year, month - 1, day);
        setFieldValue(name, birthDate.toISOString());
      } else {
        setFieldValue(name, [year, month].join('-'));
      }
    } else if (year || month || day) {
      setFieldValue(name, [year, month, day].join('-'));
    } else {
      setFieldValue(name, '');
    }
  };

  const handleBlur = () => {
    if (!touched[name]) {
      setFieldTouched(name, true);
    }
  };

  useEffect(() => {
    if (!value) {
      return;
    }

    const { valueYear, valueMonth, valueDay } = getDateValuesFromString(value);

    if (valueYear && valueMonth) {
      // @ts-ignore
      const validDate = new Date(valueYear, valueMonth, 0);
      setValidLastDay(validDate.getDate());
      if (valueDay && valueDay > validDate.getDate()) {
        setFieldValue(name, [valueYear, valueMonth].join('-'), true);
        return;
      }
    }
    // Formik Persist will update the value from browser storage,
    // We have to call setFieldValue in custom Field components
    setFieldValue(name, value);
  }, [name, value, setFieldValue, setValidLastDay]);

  return (
    <>
      <input type="hidden" {...field} {...props} />
      {/* @ts-ignore */}
      <SelectInput
        field={{
          // @ts-ignore
          onChange: e => handleChange({ year: Number(e.target.value) || '' }),
          onBlur: handleBlur,
          value: valueYear,
        }}
      >
        {[
          <option key="" value="">
            年
          </option>,
        ].concat(getIncrementalOptions(toYear, fromYear))}
      </SelectInput>
      {/* @ts-ignore */}
      <SelectInput
        field={{
          // @ts-ignore
          onChange: e => handleChange({ month: Number(e.target.value) || '' }),
          onBlur: handleBlur,
          value: valueMonth,
        }}
      >
        {[
          <option key="" value="">
            月
          </option>,
        ].concat(getIncrementalOptions(1, 12))}
      </SelectInput>
      {/* @ts-ignore */}
      <SelectInput
        field={{
          // @ts-ignore
          onChange: e => handleChange({ day: Number(e.target.value) || '' }),
          onBlur: handleBlur,
          value: valueDay,
        }}
        disabled={!valueYear || !valueMonth}
      >
        {[
          <option key="" value="">
            日
          </option>,
        ].concat(getIncrementalOptions(1, validLastDay))}
      </SelectInput>
    </>
  );
};

BirthDateSelector.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    setFieldTouched: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
  }).isRequired,
  meta: PropTypes.object,
  label: PropTypes.object,
  fromYear: PropTypes.number.isRequired,
  minAge: PropTypes.number,
};

export default BirthDateSelector;
