import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mixinStyle } from '../../../styles';
import { device } from '../../../styles/constants';

const StyledTextArea = styled.textarea`
  width: 100%;
  box-sizing: border-box;
  ${mixinStyle.INPUT_BOX};
  ${mixinStyle.INPUT_CONTENT};
  ${mixinStyle.INPUT_FOCUS};
  resize: vertical;
  height: auto;
  min-height: 176px;
  line-height: 1.5;
  &&& {
    padding-top: 16px;
    padding-bottom: 16px;
    @media ${device.mobile} {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
`;

const TextArea = (
  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  { field, form, meta, ...props } // eslint-disable-line no-unused-vars
) => <StyledTextArea {...field} {...props} />;

TextArea.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  meta: PropTypes.object,
  hasError: PropTypes.bool,
};

TextArea.defaultProps = {
  field: {},
  form: null,
  meta: null,
  hasError: false,
};

export default TextArea;
