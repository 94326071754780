import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FieldArray } from 'formik';
import ImageInput from './ImageInput';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 950px;
  margin-top: 32px;
  margin-left: -8px;
  > * {
    margin-top: 8px;
    margin-left: 8px;
  }
`;

// @ts-ignore
// @ts-ignore
const ImageInputGroup = ({ field, maxImages }) => {
  const { name, value } = field;
  return (
    <Container>
      <FieldArray
        {...field}
        render={arrayHelpers => {
          return [...value, ''].map((image, index) => {
            if (index < maxImages) {
              return (
                <ImageInput
                  key={Date.now() + index}
                  src={image}
                  name={`${name}.${index}`}
                  onSet={data => arrayHelpers.push(data)}
                  onDelete={() => arrayHelpers.remove(index)}
                />
              );
            }
          });
        }}
      />
    </Container>
  );
};

ImageInputGroup.propTypes = {
  field: PropTypes.object.isRequired,
  maxImages: PropTypes.number.isRequired,
};

export default ImageInputGroup;
