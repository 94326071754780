import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import TopError from './TopError';
import { scrollToTop } from '../../../utils/helpers';

type Props = {
  ignores?: string[];
  hideDOM?: boolean;
};

const ErrorScroller = ({ ignores = [], hideDOM = false }: Props) => {
  const { isValid, submitCount, errors } = useFormikContext();
  const hasError = Object.keys(errors).some(
    (errorKey) => !ignores.includes(errorKey)
  );

  useEffect(() => {
    if (!isValid && submitCount > 0 && hasError) {
      scrollToTop();
    }
  }, [hasError, isValid, submitCount]);

  if (hideDOM) return null;
  return (
    <div>
      {submitCount > 0 && hasError && (
        <TopError>入力内容に間違いがありますのでご確認ください</TopError>
      )}
    </div>
  );
};

export default ErrorScroller;
