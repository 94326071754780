// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { mixinStyle } from '../../../styles';
import { device } from '../../../styles/constants';

const StyledInput = styled.input`
  width: 100%;
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth.PC}px;
      @media ${device.mobile} {
        max-width: ${maxWidth.MOBILE}px;
      }
    `};
  box-sizing: border-box;
  ${mixinStyle.INPUT_BOX};
  ${mixinStyle.INPUT_CONTENT};
  ${mixinStyle.INPUT_FOCUS};
`;

const TextInput = (
  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  { field, form, meta, ...props } // eslint-disable-line no-unused-vars
) => <StyledInput {...field} {...props} />;

TextInput.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  meta: PropTypes.object,
  hasError: PropTypes.bool,
};

TextInput.defaultProps = {
  field: {},
  form: null,
  meta: null,
  hasError: false,
};

export default TextInput;
