import styled from 'styled-components';
import { typo } from '../../../styles';
import { device } from '../../../styles/constants';

const Label = styled.div`
  :not(:first-child) {
    margin-top: 40px;
  }
  ${typo.S};

  @media ${device.mobile} {
    :not(:first-child) {
      margin-top: 24px;
    }
    ${typo.XS};
  }
`;

export default Label;
