import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import Texts from '../Texts';

// @ts-ignore
const StyledErrorMessage = ({ name }) => (
  <ErrorMessage
    key={name}
    name={name}
    render={msg => <Texts.Error>{msg}</Texts.Error>}
  />
);

StyledErrorMessage.propTypes = {
  name: PropTypes.string.isRequired,
};

export default StyledErrorMessage;
